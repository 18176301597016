export default {

  props: [
    'editMode',
    'country',
    'disabled',
    'placeholder',
    'isCurrencyPicker'
  ],

  methods: {
    countrySelected() {
      this.$emit('update:country', this.con);
    },
    customFilter (item, queryText) {
      const textOne = item.name.toLowerCase()
      const searchText = queryText.toLowerCase()

      if (this.isCurrencyPicker && item.code && item.country) {
        const textTwo = item.code.toLowerCase();
        const textThree = item.country.toLowerCase();
        return textOne.indexOf(searchText) > -1 || 
          textTwo.indexOf(searchText) > -1 || 
          textThree.indexOf(searchText) > -1;        
      }

      return textOne.indexOf(searchText) > -1;
    }
  },

  data () {
    let countries = JSON.parse(localStorage.getItem('countries'));
    if (this.isCurrencyPicker) {
      let filteredCurrencies = [];
      countries.forEach(item => {
        if (item.currencies[0] && !filteredCurrencies.find(x => item.currencies[0].code == x.code)) {
          filteredCurrencies.push({
            country: item.name,
            code: item.currencies[0].code,
            name: item.currencies[0].name,
            symbol: item.currencies[0].symbol
          });
        }
      });
      countries = filteredCurrencies;
    }

    let errorText = 'Country code is required'
    if (this.isCurrencyPicker) errorText = 'Currency is required'

    return {
      search: '',
      con: this.country,
      countries: countries,
      countryRules: [
        v => !!v || errorText
      ]
    }
  }
}